<template>
  <div>
    <div id="map" style="display: block" ref="map-root">

    </div>
    <button @click="test" value="отобразить geotiff">Тестировать</button>
  </div>
</template>

<script>
  import 'ol/ol.css'
  import Map from 'ol/Map'
  import View from 'ol/View'
  import Projection from 'ol/proj/Projection'
  import {getCenter} from 'ol/extent'
  import GeoTIFF from 'ol/source/GeoTIFF';
  import WebGLTile from 'ol/layer/WebGLTile'



  export default {
    name: 'GeotiffViewer',
    data() {
      return {
        testRunned: false
      }
    },
    methods: {
      test() {
        if (this.testRunned) return;
        this.testRunned = true

        const projection = new Projection({
          code: 'EPSG:32721',
          units: 'm',
        });
        const sourceExtent = [300000, 6090260, 409760, 6200020];
        const olMap = new Map({
          target: this.$refs['map-root'],
          view: new View({
            projection: projection,
            center: getCenter(sourceExtent),
            extent: sourceExtent,
            zoom: 20,
          })
        });

        const geoTiffLayer = new WebGLTile({
          id: `geoTiffs`,
          zIndex: 3,
          source: new GeoTIFF({
            sources: [
              {
                url: '/test/ME1_RDSA_2337_101_1_RG_to_psa.tif'
              },
            ],
          }),
        });
        olMap.addLayer(geoTiffLayer);
        olMap.renderSync()
      }
    },
    mounted() {
      this.test()
    }
  }
</script>

<style scoped>
  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  #map {
    height: 400px;
    width: 100%;
    background-color: lightpink;
  }
</style>